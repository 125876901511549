.S36ykG_button {
  background-color: var(--cl-primary);
  color: var(--cl-background);
  appearance: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: .5em 1em;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
}

.S36ykG_button:disabled {
  cursor: default;
  background-color: var(--cl-text);
}

.S36ykG_button[data-style="text"] {
  color: var(--cl-text);
  text-transform: uppercase;
  background-color: #0000;
  padding: 0;
  font-size: .88rem;
  font-weight: normal;
}

.NxuMsG_home button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._SAVpq_edit {
  width: 50rem;
  max-width: 100vw;
  margin: auto;
  padding: 2rem;
}

._SAVpq_edit > span {
  margin-bottom: 1em;
  display: block;
}

._SAVpq_questions {
  margin: 4rem 0;
  padding: 0;
  list-style: none;
}

._SAVpq_edit button {
  width: fit-content;
}

._SAVpq_title {
  height: 2rem;
  margin-top: 2rem;
  display: flex;
}

._SAVpq_title button {
  height: 100%;
  min-width: 7ch;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 1em;
}

._SAVpq_title input {
  appearance: none;
  height: 100%;
  border: none;
  border: 1px solid var(--cl-text);
  font-size: inherit;
  color: var(--cl-text);
  width: 100%;
  background: none;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 1em;
  font-family: inherit;
}

._SAVpq_title input:focus {
  outline: none;
}

._SAVpq_question {
  border-top: 2px solid var(--cl-text);
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  display: flex;
}

._SAVpq_question:last-of-type {
  border-bottom: 2px solid var(--cl-text);
}

._SAVpq_question textarea {
  width: 100%;
}

._SAVpq_saveQuestion {
  align-self: end;
}

._SAVpq_saveQuestion:disabled {
  opacity: 0;
}

._SAVpq_question label {
  align-items: center;
  gap: .5em;
  display: flex;
}

._SAVpq_answers {
  padding: 0;
  list-style: none;
}

._SAVpq_answerOption {
  gap: 1em;
  display: flex;
}

._SAVpq_answerOption > :first-child {
  flex-grow: 1;
}

.Pr1QXG_wrap {
  --padd-top: .8em;
  position: relative;
}

.Pr1QXG_text {
  border: 1px solid var(--cl-text);
  color: var(--cl-text);
  padding: var(--padd-top) 1em;
  resize: none;
  background-color: #0000;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1em;
}

.Pr1QXG_text:focus {
  outline: none;
}

.Pr1QXG_wrap label {
  left: .8em;
  top: var(--padd-top);
  background-color: var(--cl-background);
  transform: translateY(calc(var(--padd-top) * -1 - 50%) ) scale(.88);
  transform-origin: 0;
  padding: 0 .2em;
  transition: transform .3s, opacity .3s;
  position: absolute;
}

.Pr1QXG_text:empty:not(:focus) + label {
  opacity: .5;
  transform: none;
}

.DjHbyq_root {
  width: 50rem;
  max-width: 100vw;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  padding: 2rem;
  display: flex;
}

.DjHbyq_playerList {
  padding: 0;
  list-style: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.DjHbyq_question {
  color: var(--cl-primary);
  font-size: 1.2rem;
  font-weight: 500;
}

.DjHbyq_multipleChoice {
  counter-reset: choice 0;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.DjHbyq_multipleChoice[data-disabed="true"] {
  opacity: .5;
}

.DjHbyq_multipleChoice li {
  border: 1px solid var(--cl-text);
  width: fit-content;
  min-width: 12rem;
  cursor: pointer;
  border-radius: 5px;
  padding: .5rem 1rem .5rem .5rem;
}

.DjHbyq_multipleChoice li:not([data-selected="true"]):hover {
  background-color: #fff4;
}

.DjHbyq_option {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DjHbyq_option:before {
  counter-increment: choice 1;
  content: counter(choice, upper-alpha);
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  border: 1px solid var(--cl-text);
  background-color: var(--cl-background);
  border-radius: 2.5px;
  font-size: .8rem;
  line-height: 1.5rem;
}

.DjHbyq_multipleChoice li[data-selected="true"] .DjHbyq_option:before {
  background-color: var(--cl-primary);
  color: var(--cl-background);
  border-color: var(--cl-background);
}

.DjHbyq_freeAnswer {
  width: 100%;
}

.DjHbyq_freeAnswer textarea {
  width: 100%;
  margin-bottom: 1rem;
}

.DjHbyq_scoreBoard {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.tPtoHW_countdown {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
}

.Yfhl5G_host {
  font-size: 1rem;
}

.DzSsVq_stack {
  width: 100vw;
  flex-direction: column;
  gap: 1px;
  padding: 1px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.DzSsVq_item {
  width: 100%;
  background-color: var(--cl-error);
  padding: .5em 1em;
}

.DzSsVq_item[data-type="warning"] {
  background-color: var(--cl-warning);
}

/*# sourceMappingURL=index.a72dc1c7.css.map */
